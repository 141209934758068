import { type NmiTransactionCreatedResponseDto } from '@innerwell/dtos';
import { create } from 'zustand';

import { type AddressValidationStatus } from '@/components/Forms/Payment/useAddressValidation';

type Status = 'notInitiated' | 'loading' | 'success' | 'failed';

export const paymentProviders = {
   card: 'card',
   existingCard: 'existing-card',
   googlePay: 'google-pay',
   applePay: 'apple-pay',
} as const;

export type PaymentProvider =
   (typeof paymentProviders)[keyof typeof paymentProviders];

type NMIFieldValidation = {
   valid?: boolean;
   message?: string;
};
export interface NMIFieldsValidation {
   ccnumber: NMIFieldValidation;
   ccexp: NMIFieldValidation;
   cvv: NMIFieldValidation;
}

interface CheckoutState {
   paymentProvider: PaymentProvider;
   setPaymentProvider: (provider: PaymentProvider) => void;
   paymentToken: string | null;
   setPaymentToken: (token: string | null) => void;
   formEl: HTMLFormElement | null;
   setFormEl: (el: HTMLFormElement | null) => void;

   // Status
   status: Status;
   setStatus: (status: Status) => void;
   addressValidationStatus: AddressValidationStatus | null;
   setAddressValidationStatus: (status: AddressValidationStatus | null) => void;
   isCollectJsScriptLoaded: boolean;
   setIsCollectJsScriptLoaded: (status: boolean) => void;

   // Validation
   nmiFieldsValidation: NMIFieldsValidation;
   setNMIFieldsValidation: (validation: NMIFieldsValidation) => void;
   skipAddressValidation: boolean;
   setSkipAddressValidation: (skip: boolean) => void;

   // Errors
   transactionError: NmiTransactionCreatedResponseDto | null;
   setTransactionError: (error: NmiTransactionCreatedResponseDto) => void;
}

export const useCheckoutStore = create<CheckoutState>((set) => ({
   paymentProvider: paymentProviders.existingCard,
   setPaymentProvider: (provider: PaymentProvider) =>
      set({ paymentProvider: provider }),
   paymentToken: null,
   setPaymentToken: (token: string | null) => set({ paymentToken: token }),
   formEl: null,
   setFormEl: (el: HTMLFormElement | null) => set({ formEl: el }),

   // Status
   status: 'notInitiated',
   setStatus: (status: Status) => set({ status }),
   isCollectJsScriptLoaded: false,
   setIsCollectJsScriptLoaded: (status: boolean) =>
      set({ isCollectJsScriptLoaded: status }),

   // Validation
   nmiFieldsValidation: {
      ccnumber: {},
      ccexp: {},
      cvv: {},
   },
   setNMIFieldsValidation: (validation: NMIFieldsValidation) =>
      set({ nmiFieldsValidation: validation }),
   skipAddressValidation: false,
   setSkipAddressValidation: (skip: boolean) =>
      set({ skipAddressValidation: skip }),

   addressValidationStatus: null,
   setAddressValidationStatus: (status: AddressValidationStatus | null) =>
      set({ addressValidationStatus: status }),

   // Errors
   transactionError: null,
   setTransactionError: (error: NmiTransactionCreatedResponseDto) =>
      set({ transactionError: error }),
}));
