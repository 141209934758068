import {
   ExtendedProgramPhases,
   FollowOnProgramPhases,
   FoundationProgramPhases,
   type InnerwellCartItem,
   IntakeProgramPhases,
   PaymentCode,
   SharedProgramPhases,
   TwoDosesProgramPhases,
   WelkinEncounterTemplateNames,
   type WelkinProgramPhases,
   WelkinPrograms,
} from '@innerwell/dtos';
import { getTimeUnitsFromPaymentFrequency } from '@innerwell/utils';
import _ from 'lodash';

import { type PlanPricing } from '@/hooks/usePlanPricing';

import { prettifyCurrency } from './formatting';

export const checkIfExtendedProgramPhaseExists: (
   phase: string,
) => asserts phase is keyof typeof ExtendedProgramPhases = (phase) => {
   if (!(phase in ExtendedProgramPhases)) {
      throw new Error(
         `Phase ${phase} does not exist' in ExtendedProgramPhases`,
      );
   }
};

export const checkIfSharedProgramPhaseExists: (
   phase: string,
) => asserts phase is keyof typeof SharedProgramPhases = (phase) => {
   if (!(phase in SharedProgramPhases)) {
      throw new Error(`Phase ${phase} does not exist' in SharedProgramPhases`);
   }
};

export const checkIfFoundationProgramPhaseExists: (
   phase: string,
) => asserts phase is keyof typeof FoundationProgramPhases = (phase) => {
   if (!(phase in FoundationProgramPhases)) {
      throw new Error(
         `Phase ${phase} does not exist' in FoundationProgramPhases`,
      );
   }
};

export const shortenPaymentFrequencyString = (frequency: PaymentCode) => {
   if (frequency === PaymentCode.Weekly) {
      return 'wk';
   }
   if (
      [
         PaymentCode.Monthly,
         PaymentCode.TwoMonths,
         PaymentCode.FourMonths,
      ].includes(frequency)
   ) {
      return 'mo';
   }

   return '-';
};

export const instalmentPaymentCodes = [
   PaymentCode.Monthly,
   PaymentCode.Weekly,
   PaymentCode.TwoMonths,
   PaymentCode.FourMonths,
];

export const getVariantInstalmentPaymentCode = (frequency: PaymentCode[]) =>
   frequency.find((f) => instalmentPaymentCodes.includes(f));

export const getProductPriceString = (plan: PlanPricing) => {
   if (
      _.isNumber(plan.installmentSpecialPrice) &&
      !!plan.installmentPaymentCode
   ) {
      return `${prettifyCurrency(plan.installmentSpecialPrice, 1)}
      /
      ${shortenPaymentFrequencyString(
         plan.installmentPaymentCode,
      )} for ${plan.numberOfPayments} ${getTimeUnitsFromPaymentFrequency(plan.installmentPaymentCode)}.`;
   }
   return `${prettifyCurrency(plan.specialPrice, 1)} - Pay today`;
};

export const convertNumberOfPaymentsToMonths = (
   frequency: PaymentCode,
   numberOfPayments: number,
) => {
   if (!frequency) {
      return '-';
   }

   let monthlyNumberOfPayments = 0;

   if (frequency === PaymentCode.Weekly) {
      monthlyNumberOfPayments = numberOfPayments / 4;
   }

   return Math.floor(monthlyNumberOfPayments);
};

export const getPaymentBilledForMonthsInfo = (
   frequency: PaymentCode,
   numberOfPayments: number,
) => {
   return `Billed ${frequency} for ${
      numberOfPayments === 0
         ? '-'
         : convertNumberOfPaymentsToMonths(frequency, numberOfPayments)
   } months`;
};

export const getSupportedPaymentMethods = (items: InnerwellCartItem[]) => {
   return items.slice(1).reduce<PaymentCode[]>(
      (acc, item) => {
         // Intersect first item's payment methods with the rest
         return _.intersection(acc, item.allowedPaymentMethods);
      },
      // Set payment methods to the first item in the cart
      items.at(0)?.allowedPaymentMethods ?? [
         // Just default to in-full if nothing is in cart as we require in-full or
         // free in getCartPayments.
         PaymentCode.OnceInFull,
      ],
   );
};

export const getAdsPhaseInfo = (phase: WelkinProgramPhases) => {
   const phaseInfo = phase.match(/[\d]/g);

   if (!phaseInfo) {
      return null;
   }

   const [adsBatch, adsDosingNumber, adsDosingLength] = phaseInfo.map((n) =>
      parseInt(n),
   );

   return {
      adsBatch,
      adsDosingNumber,
      adsDosingLength,
   };
};

export const THERAPIST_ENCOUNTERS = [
   WelkinEncounterTemplateNames.MeetYourTherapistALaCarte,
   WelkinEncounterTemplateNames.TherapistIntake50Min,
   WelkinEncounterTemplateNames.FreeTherapySession,
   WelkinEncounterTemplateNames.TherapistIntake,
   WelkinEncounterTemplateNames.TherapistPrepCall,
   WelkinEncounterTemplateNames.TherapistSDS1,
   WelkinEncounterTemplateNames.TherapistSDS2,
   WelkinEncounterTemplateNames.Integration30MinALaCarte,
   WelkinEncounterTemplateNames.Integration50MinALaCarte,
   WelkinEncounterTemplateNames.Preparation30MinALaCarte,
   WelkinEncounterTemplateNames.Preparation50MinALaCarte,
   WelkinEncounterTemplateNames.SupervisedDosingALaCarte,
   WelkinEncounterTemplateNames.IntegrationSession1,
   WelkinEncounterTemplateNames.IntegrationSession2,
   WelkinEncounterTemplateNames.IntegrationSession3,
   WelkinEncounterTemplateNames.IntegrationSession4,
   WelkinEncounterTemplateNames.EmdrSession,
   WelkinEncounterTemplateNames.EmdrIntake,
   WelkinEncounterTemplateNames.EmdrSessionInsurance,
   WelkinEncounterTemplateNames.EmdrIntakeInsurance,
];

export const updateProgramPhaseScheduleMap = (
   program: WelkinPrograms,
   phase: WelkinProgramPhases,
   templateName: WelkinEncounterTemplateNames,
) => {
   if (
      program === WelkinPrograms.Intake &&
      // NOTE(AHC-246): With one intake flow, we might first schedule MedicalConsultPsychiatry here and then be offered
      // to schedule TherapistIntake50Min. This happens when purchasing Integrated Psychiatry and Therapy.
      // After MedicalConsultPsychiatry, automation will switch to Extended plan. After that we no longer need to push
      // to MedicalConsultSchedule after scheduling TherapistIntake50Min
      phase !== IntakeProgramPhases.ExtendedLayoutUnlocked &&
      [
         WelkinEncounterTemplateNames.MedicalConsult,
         WelkinEncounterTemplateNames.MeetYourTherapistInsurance,
         WelkinEncounterTemplateNames.MedicalConsultPsychiatry,
         WelkinEncounterTemplateNames.TherapistIntake50Min,
         WelkinEncounterTemplateNames.EmdrSession,
         WelkinEncounterTemplateNames.EmdrIntake,
         WelkinEncounterTemplateNames.EmdrIntakeInsurance,
      ].includes(templateName)
   ) {
      return IntakeProgramPhases.MedicalConsultSchedule;
   }

   if (program === WelkinPrograms.FollowOn) {
      if (templateName === WelkinEncounterTemplateNames.MedicalCheckIn) {
         return FollowOnProgramPhases.ScheduleMedicalCheckinFinished;
      }

      if (templateName === WelkinEncounterTemplateNames.CapstoneAppointment) {
         return FollowOnProgramPhases.ScheduleCapstoneFinished;
      }
   }

   if (program === WelkinPrograms.TwoDoses) {
      if (templateName === WelkinEncounterTemplateNames.CapstoneAppointment) {
         return TwoDosesProgramPhases.ScheduleCapstoneFinished;
      }
   }

   // TODO(AHC-246) WelkinPrograms.GeneralPsychiatryBank
};

// @TODO: not working on HomeExtended
export const generateSchedulingLink = ({
   encounterName,
   locationId,
   staffId,
   date,
   forceFullUrl = false,
}: {
   encounterName: WelkinEncounterTemplateNames;
   locationId: number | undefined;
   staffId?: number;
   date?: string;
   forceFullUrl?: boolean;
}) => {
   const url = `/schedule/${encounterName}`;

   if (!THERAPIST_ENCOUNTERS.includes(encounterName) && !forceFullUrl) {
      return url;
   }

   const searchParams = new URLSearchParams();

   if (staffId) {
      searchParams.set('staffId', staffId.toString());
   }

   if (date) {
      searchParams.set('date', date);
   }

   return `${url}/virtual/${locationId}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`;
};
